// Body
$body-bg: #f8fafc;

// Typography
// $font-family-sans-serif: 'Nunito', sans-serif;
$font-family: 'Roboto', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

$secondary: #5D3FD3 !default;

.pagination .page-item.active .page-link {
    background-color: #5D3FD3;
    border-color: #5D3FD3;
}
