// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');



$fa-font-path:        "../webfonts";
// Font Awesome
@import '~@fortawesome/fontawesome-free/scss/brands';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/fontawesome';


// Variables
@import 'variables';


// Bootstrap
@import '~bootstrap/scss/bootstrap';


// admin-lte
@import '~admin-lte/dist/css/adminlte.css';

@import '~admin-lte/plugins/datatables-bs4/css/dataTables.bootstrap4.min.css';
@import '~admin-lte/plugins/datatables-responsive/css/responsive.bootstrap4.min.css';
@import '~admin-lte/plugins/datatables-buttons/css/buttons.bootstrap4.min.css';

@import '~admin-lte/plugins/sweetalert2-theme-bootstrap-4/bootstrap-4.min.css';
@import '~admin-lte/plugins/toastr/toastr.min.css';

@import '~admin-lte/plugins/select2/css/select2.min.css';
@import '~admin-lte/plugins/select2-bootstrap4-theme/select2-bootstrap4.min.css';

@import '~admin-lte/plugins/daterangepicker/daterangepicker.css';
